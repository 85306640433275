app.config(['$stateProvider', '$urlRouterProvider', '$locationProvider',
    function ($stateProvider, $urlRouterProvider, $locationProvider, templateProvider) {
        var $modalInstance;

        // States

        // $urlRouterProvider.when('/', ['$state', '$match', function ($state, $match) {
        //     $state.go('home');
        // }]);
        $stateProvider
            .state('home', {
                url: '/home',
                views: {
                    'top': {
                        templateUrl: 'templates/home/search-2.html',
                    },
                    'process': {
                        templateUrl: 'templates/home/process.html',
                        controller: 'AppCtrl'
                    },
                    'neighbourhood': {
                        templateUrl: 'templates/home/neighbourhood.html',
                        controller: 'neighbourhoodCtrl'
                    },
                    'callBack': {
                        templateUrl: 'templates/callBack.html',
                        controller: 'callBackCtrl'
                    },
                }

            })

            .state('about', {
                url: '/about',
                views: {
                    'abcd': {
                        templateUrl: 'templates/header.html',
                        controller: 'userController'
                    },
                }
            })
            .state('privacy', {
                url: '/privacy',
            })
            .state('terms', {
                url: '/terms',
            })
            .state('career', {
                url: '/career',
            })
            .state('faq', {
                url: '/faq',
            })
            .state('amc', {
                url: '/amc',
            })
            .state('service', {
                url: '/service',
            })
            .state('contact', {
                url: '/contact',
                views: {
                    'contactUs': {
                        templateUrl: 'templates/contact.html',
                        controller: 'contactController'
                    },
                }
            })
            .state('location', {
                url: '/location/:id/:loc_name?',
                controller: 'locationCtrl',
                templateProvider: ['$rootScope', '$templateFactory', function ($rootScope, $templateFactory) {
                    if (ltype) {
                        return $templateFactory.fromUrl('templates/neighbourhood/location/' + ltype + '.html');
                    }
                }],
                resolve: {
                    'resolveData': ['$stateParams', '$locationModel', function ($stateParams, $locationModel) {
                        if ($stateParams.hasOwnProperty('id') && $stateParams.id > 0) {
                            return $locationModel.locationInfo($stateParams.id);
                        }
                    }]
                }
            })
            .state('society', {
                url: '/society/:id/:soc_name?',
                templateUrl: 'templates/neighbourhood/society.html',
                controller: 'societyCtrl',
                resolve: {
                    'resolveData': ['$stateParams', '$societyModel', function ($stateParams, $societyModel) {
                        return $societyModel.societyInfo($stateParams.id);
                    }]
                }
            })
            .state('reference', {
                url: '/reference',
                templateUrl: 'templates/reference.html',
                controller: 'referenceCtrl'
            })
            .state('owner', {
                url: '/owner?p&r&s',
                onEnter: ['$stateParams', '$state', '$uibModal', '$rootScope', '$location',
                    function ($stateParams, $state, $uibModal, $rootScope, $location) {
                        if ($stateParams.p === 'post-property') {
                            $modalInstance =
                                $uibModal.open({
                                    templateUrl: 'templates/owner/post-property.html',
                                    controller: 'ownerController',
                                    windowClass: 'list_property',
                                })
                                //   change route after modal result
                                    .result.then(function () {
                                    // change route after clicking OK button
                                    $location.url($location.path())
//                                            $state.go('tenant');
                                }, function () {
                                    // change route after clicking Cancel button or clicking background
//                                            $state.go('tenant');
                                    $location.url($location.path())
                                })
//                                    .result.finally(function () {
////                                        console.log($state.$current.parent);
////                                        $state.go($state.$current.parent);
////                                        if ($rootScope.previousState === $rootScope.currentState) {
////                                            $state.go($state.current.name);
////                                        } else {
//                                        $state.go($rootScope.previousState);
////                                        }
//                                        console.log($state);
//                                    })

                        }
                    }
                ],
                onExit: [
                    function ($modalInstance) {
                        if ($modalInstance) {
                            $modalInstance.close();
                        }
                    }],
                views: {
                    'callBack': {
                        templateUrl: 'templates/callBack.html',
                        controller: 'callBackCtrl'
                    },
                }
            })
            .state('tenant', {
                url: '/tenant?p&r&s',
                onEnter: ['$stateParams', '$state', '$uibModal', '$rootScope', '$location',
                    function ($stateParams, $state, $uibModal, $rootScope, $location) {
                        if ($stateParams.p === 'post-requirment') {
                            $modalInstance =
                                $uibModal.open({
                                    templateUrl: 'templates/tenant/post-requirment.html',
                                    controller: 'tenantController',
                                    windowClass: 'list_property',
                                })
                                //   change route after modal result
                                    .result.then(function () {
                                    // change route after clicking OK button
                                    $location.url($location.path())
//                                            $state.go('tenant');
                                }, function () {
                                    // change route after clicking Cancel button or clicking background
//                                            $state.go('tenant');
                                    $location.url($location.path())
                                })
//                                    .result.finally(function () {
////                                        console.log($state.$current.parent);
////                                        $state.go($state.$current.parent);
////                                        if ($rootScope.previousState === $rootScope.currentState) {
////                                            $state.go($state.current.name);
////                                        } else {
//                                        $state.go($rootScope.previousState);
////                                        }
//                                        console.log($state);
//                                    })

                        }
                    }],
                onExit: [
                    function ($modalInstance) {
                        if ($modalInstance) {
                            $modalInstance.close();
                        }
                    }],
                views: {
                    'callBack': {
                        templateUrl: 'templates/callBack.html',
                        controller: 'callBackCtrl'
                    },
                }
            })
            .state('gowork', {
                url: '/coworking-space',
                templateUrl: 'templates/gowork/list.html',
            })
            .state('gowork_deatils', {
                url: '/coworking-space/:path',
                templateProvider: ['$stateParams', '$templateFactory', function ($stateParams, $templateFactory) {
                    if ($stateParams.hasOwnProperty('path'))
                        return $templateFactory.fromUrl('templates/gowork/' + $stateParams.path + '.html');

                }],
                controller: 'detailsCtrl'
            })
            .state('searchList', {
                url: '/search?latitude&longitude&location&city&room&house_type&min_price&max_price&status',
                // views: {
                //     'propertylist': {

                // resolve: {
                //     "mapData": function ($userModel, $stateParams) {
                //         $mapParam = {view: 'map'}
                //         return $userModel.getListProperty($mapParam);
                //     }
                // },
                templateUrl: 'templates/property/list.html',
                controller: 'listCtrl',

                // },
//                        'propertylist': {
//                            templateUrl: 'templates/property/propertyList.html',
//                        },
//                        'pmap': {
//                            templateUrl: 'templates/property/map.html',
//                            controller: 'listController',
//                        }

                // }
            })
            .state('dashboard', {
                url: '/dashboard',
                abstract: true,
                template: '<div ui-view></div>',
                authenticate: true,
                // templateUrl: 'templates/dashboard/dashboard.html',
                // controller: 'dashboardController',
            })
            .state('dashboard.property', {
                url: '',
                templateUrl: 'templates/dashboard/partial/property-partial.html',
                resolve: {
                    "resovleData": ['$dashboardModel', function ($dashboardModel) {
                        return $dashboardModel.getOwnerProperty();
                    }],
                },
                authenticate: true,
                controller: 'dashboardController',
            })
            .state('dashboard.rooms', {
                url: '/rooms/{id}/{name}',
                templateUrl: 'templates/dashboard/partial/rooms.html',
                resolve: {
                    "resovleData": ['$roomModel', '$stateParams', function ($roomModel, $stateParams) {
                        return $roomModel.getRooms($stateParams.id);
                    }],
                },
                authenticate: true,
                controller: 'roomCtrl'
            })
            .state('dashboard.profile', {
                url: '/profile',
                templateUrl: 'templates/dashboard/my-profile.html',
                controller: 'profileController',
                authenticate: true,
            })
            .state('dashboard.booking', {
                url: '/bookings',
                templateUrl: 'templates/dashboard/booking/mybooking.html',
                authenticate: true,
                resolve: {
                    "resovleData": ['$bookingsModel', '$stateParams', function ($bookingsModel, $stateParams) {
                        return $bookingsModel.mybookings();
                    }],
                },
                controller: 'mybookingCtrl'
            })

            .state('dashboard.payment', {
                url: '/payment',
                authenticate: true,
                templateUrl: 'templates/dashboard/payment-history.html',
            })
            .state('dashboard.password', {
                url: '/password',
                authenticate: true,
                templateUrl: 'templates/dashboard/change-password.html',
                controller: 'changePasswordController'
            })
            .state('neighbourhood', {
                url: '/neighbourhood/location',
                abstract: true,
                template: '<div ui-view></div>',
                authenticate: true,
            })
            .state('neighbourhood.loc_list', {
                url: '',
                templateUrl: 'templates/dashboard/neighbourhood/location-list.html',
                controller: 'listLocationCtrl',
                resolve: {
                    'resolveData': ['$neighbourhoodModel', function ($neighbourhoodModel) {
                        return $neighbourhoodModel.getLocation();
                    }]
                }
            })
            .state('neighbourhood.soc_list', {
                url: '/:name/:locId/society',
                templateUrl: 'templates/dashboard/neighbourhood/society-list.html',
                resolve: {
                    'resolveData': ['$stateParams', '$rootScope', '$neighbourhoodModel', function ($stateParams, $rootScope, $neighbourhoodModel) {
                        if ($stateParams.hasOwnProperty('locId') && $stateParams.locId > 0) {
                            return $neighbourhoodModel.getSociety($stateParams);
                        } else {
                            $rootScope.back();
                        }
                    }]
                },
                controller: 'societyListCtrl',
            })
            .state('neighbourhood.units', {
                url: '/{name}/{id}/units',
                templateUrl: 'templates/dashboard/neighbourhood/unit-list.html',
                resolve: {
                    'resolveData': ['$stateParams', '$rootScope', '$neighbourhoodModel', function ($stateParams, $rootScope, $neighbourhoodModel) {
                        if ($stateParams.hasOwnProperty('id') && $stateParams.id > 0) {
                            return $neighbourhoodModel.getSocietyUnits($stateParams.id);
                        } else {
                            $rootScope.back();
                        }
                    }]
                },
                controller: 'unitListCtrl',
            })
            .state('crm', {
                url: '/crm',
                abstract: true,
                template: '<div ui-view></div>',
                authenticate: true,
            })
            .state('crm.home', {
                url: '',
                templateUrl: 'templates/dashboard/crm/requirement_list.html',
                authenticate: true,
                controller: 'listLeadsCtrl',
                resolve: {
                    'resolveData': ['$rootScope', '$crmModel', function ($rootScope, $crmModel) {
                        var data = {
                            id: $rootScope.authuser.exe_id,
                            secret: $rootScope.authToken,
                            client: "jwt",
                            condition: [
                                ["exe_id", $rootScope.authuser.exe_id],
                            ],
                            paginate: 10,
                        }
                        return $crmModel.getRequirements(data);
                    }]
                }
            })
            // .state('crm.leads', {
            //     url: '/leads',
            //     templateUrl: 'templates/dashboard/crm/list.html',
            //     authenticate: true,
            //     controller: 'listLocationCtrl',
            //     resolve: {
            //         'resolveData': ['$rootScope', '$crmModel', function ($rootScope, $crmModel) {
            //             var data = {
            //                 id: $rootScope.authuser.exe_id,
            //                 secret: $rootScope.authToken,
            //                 client: "jwt",
            //                 condition: {
            //                     exe_id: $rootScope.authuser.exe_id
            //                 }
            //             }
            //             return $crmModel.leadList(data);
            //         }]
            //     }
            // })
            .state('pdetail', {
                url: '/details/:id',
                views: {
                    'pdetails': {
                        resolve: {
                            "propertyData": ['$detailModel', '$stateParams', function ($detailModel, $stateParams) {
                                return $detailModel.getPropertyDetails($stateParams.id);
                            }]
                        },
                        templateUrl: 'templates/property/details.html',
                        controller: 'overviewCtrl',
                    },
                    // 'psimilar': {
                    //     templateUrl: 'templates/property/similarProperty.html',
                    //     controller: 'similarCtrl'
                    // },
                    'footer': {
                        templateUrl: 'templates/footer.html',
                    }
                }
            })
            .state('checkout', {
                url: '/checkout/init',
                abstract: true,
                templateUrl: 'templates/booking/checkout/checkout.html',
            })
            .state('checkout.auth', {
                url: '',
                templateProvider: ['$rootScope', '$templateFactory', function ($rootScope, $templateFactory) {
                    // if condition is true, return the template for column two
                    if ($rootScope.hasOwnProperty('authcheck') && $rootScope.authcheck) {
                        if ($rootScope.hasOwnProperty('checkoutData') && $rootScope.checkoutData.hasOwnProperty('bksum') && $rootScope.checkoutData.bksum) {
                            return $templateFactory.fromUrl('templates/booking/checkout/bookingSummary.html');
                        }
                        return $templateFactory.fromUrl('templates/booking/checkout/personalDetail.html');
                    } else {
                        if ($rootScope.hasOwnProperty('checkoutData') && $rootScope.checkoutData.hasOwnProperty('password') && $rootScope.checkoutData.password) {
                            return $templateFactory.fromUrl('templates/booking/checkout/chkPass.html');
                        }
                        if ($rootScope.hasOwnProperty('checkoutData') && $rootScope.checkoutData.hasOwnProperty('register') && $rootScope.checkoutData.register) {
                            return $templateFactory.fromUrl('templates/booking/checkout/chkRegister.html');
                        }
                        if ($rootScope.hasOwnProperty('checkoutData') && $rootScope.checkoutData.hasOwnProperty('reset') && $rootScope.checkoutData.reset) {
                            return $templateFactory.fromUrl('templates/booking/checkout/chkReset.html');
                        }
                        return $templateFactory.fromUrl('templates/booking/checkout/chkEmail.html');
                    }

                }],
                controller: 'checkOutCtrl',
                resolve: {
                    cartData: ['$cartModel', '$state', '$rootScope', '$cookies', '$timeout', '$q', 'base64', function ($cartModel, $state, $rootScope, $cookies, $timeout, $q, base64) {
                        if ($cookies.get('_ord')) {
                            var data = base64.decode($cookies.get('_ord'));
                            return $cartModel.getCartItem(data);
                        } else {
                            $rootScope.error_type = 'warning';
                            $rootScope.error_message = "Sorry! You have not selected any Room or Bed for booking.";
                            $timeout(function () {
                                $state.go('checkout.failed');
                            }, 0);
                            return $q.reject()
                        }
                    }],
                }
            })
            .state('checkout.failed', {
                url: '/failed',
                templateUrl: 'templates/errors/error.html',
                resolve: {
                    cartData: ['$cookies', '$q', function ($cookies, $q) {
                        $cookies.remove('_ord');
                        // return $q.reject()
                    }]
                }
            })
            .state('ticket', {
                url: '/booking/complete/:id',
                templateUrl: 'templates/booking/ticket.html',
                resolve: {
                    'resolveData': ['$stateParams', '$ticketModel', function ($stateParams, $ticketModel) {
                        return $ticketModel.createTicket($stateParams);
                    }],
                },
                controller: 'ticketCtrl'
            })
            .state(pstate + '.login', {
                url: '/login?q',
                onEnter: ['$stateParams', '$state', '$uibModal', '$rootScope',
                    function ($stateParams, $state, $uibModal, $rootScope) {
                        $modalInstance =
                            $uibModal.open({
                                templateUrl: 'templates/auth/login.html',
                                controller: 'authCtrl',
                                windowClass: 'fullpage',
                            })
                            //   change route after modal result
                                .result.then(function () {
                                // change route after clicking OK button
                                $state.go('^');
                            }, function () {
                                // change route after clicking Cancel button or clicking background
                                $state.go('^');
                            })
//                                    .result.finally(function () {
////                                        console.log($state.$current.parent);
////                                        $state.go($state.$current.parent);
////                                        if ($rootScope.previousState === $rootScope.currentState) {
////                                            $state.go($state.current.name);
////                                        } else {
//                                        $state.go($rootScope.previousState);
////                                        }
//                                        console.log($state);
//                                    })

                    }
                ],
                onExit: [
                    function ($modalInstance) {
                        if ($modalInstance) {
                            $modalInstance.close();
                        }
                    }]
            })
            .state(pstate + '.register', {
                url: '/register',
                onEnter: ['$stateParams', '$state', '$uibModal', '$rootScope',
                    function ($stateParams, $state, $uibModal, $rootScope) {
                        $uibModal
                            .open({
                                templateUrl: 'templates/auth/register.html',
                                controller: 'authCtrl',
                                windowClass: 'fullpage',
                            })
                            //   change route after modal result
                            .result.then(function () {
                            // change route after clicking OK button
                            $state.go('^');
                        }, function () {
                            // change route after clicking Cancel button or clicking background
                            $state.go('^');
                        })
//                                    .result.finally(function () {
////                                        console.log($state.$current.parent);
////                                        $state.go($state.$current.parent);
////                                        if ($rootScope.previousState === $rootScope.currentState) {
////                                            $state.go($state.current.name);
////                                        } else {
//                                        $state.go($rootScope.previousState);
////                                        }
//                                        console.log($state);
//                                    })

                    }
                ]
            })
            .state(pstate + '.reset', {
                url: '/reset',
                onEnter: ['$stateParams', '$state', '$uibModal', '$rootScope',
                    function ($stateParams, $state, $uibModal, $rootScope) {
                        $uibModal
                            .open({
                                templateUrl: 'templates/auth/reset.html',
                                controller: 'authCtrl',
                                windowClass: 'fullpage',
                            })
                            //   change route after modal result
                            .result.then(function () {
                            // change route after clicking OK button
                            $state.go('^');
                        }, function () {
                            // change route after clicking Cancel button or clicking background
                            $state.go('^');
                        })
                    }
                ]
            })
            .state(pstate + '.pproperty', {
                url: '/postproperty',
                onEnter: ['$stateParams', '$state', '$uibModal', '$rootScope',
                    function ($stateParams, $state, $uibModal, $rootScope) {
                        $uibModal
                            .open({
                                templateUrl: 'templates/postproperty.html',
                                controller: 'ppropertyController',
                            })
                            //   change route after modal result
                            .result.then(function () {
                            // change route after clicking OK button
                            $state.go('^');
                        }, function () {
                            // change route after clicking Cancel button or clicking background
                            $state.go('^');
                        })
//                                    .result.finally(function () {
////                                        console.log($state.$current.parent);
////                                        $state.go($state.$current.parent);
////                                        if ($rootScope.previousState === $rootScope.currentState) {
////                                            $state.go($state.current.name);
////                                        } else {
//                                        $state.go($rootScope.previousState);
////                                        }
//                                        console.log($state);
//                                    })

                    }
                ]
            })


        $locationProvider.html5Mode(true);
        $urlRouterProvider.otherwise('/home');
    }
])

