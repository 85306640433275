app
    .directive('showErrors', ['$timeout', 'showErrorsConfig', function ($timeout, showErrorsConfig) {
        var getShowSuccess, link;
        getShowSuccess = function (options) {
            var showSuccess;
            showSuccess = showErrorsConfig.showSuccess;
            if (options && options.showSuccess != null) {
                showSuccess = options.showSuccess;
            }
            return showSuccess;
        };
        link = function (scope, el, attrs, formCtrl) {
            var blurred, inputEl, inputName, inputNgEl, options, showSuccess, toggleClasses;
            blurred = false;
            options = scope.$eval(attrs.showErrors);
            showSuccess = getShowSuccess(options);
            inputEl = el[0].querySelector('[name]');
            inputNgEl = angular.element(inputEl);
            inputName = inputNgEl.attr('name');
            if (!inputName) {
                throw 'show-errors element has no child input elements with a \'name\' attribute';
            }
            inputNgEl.bind('blur', function () {
                blurred = true;
                return toggleClasses(formCtrl[inputName].$invalid);
            });
            scope.$watch(function () {
                return formCtrl[inputName] && formCtrl[inputName].$invalid;
            }, function (invalid) {
                if (!blurred) {
                    return;
                }
                return toggleClasses(invalid);
            });
            scope.$on('show-errors-check-validity', function () {
                return toggleClasses(formCtrl[inputName].$invalid);
            });
            scope.$on('show-errors-reset', function () {
                return $timeout(function () {
                    el.removeClass('has-error');
                    el.removeClass('has-success');
                    return blurred = false;
                }, 0, false);
            });
            return toggleClasses = function (invalid) {
                el.toggleClass('has-error', invalid);
                if (showSuccess) {
                    return el.toggleClass('has-success', !invalid);
                }
            };
        };
        return {
            restrict: 'A',
            require: '^form',
            compile: function (elem, attrs) {
                if (!elem.hasClass('form-group')) {
                    throw 'show-errors element does not have the \'form-group\' class';
                }
                return link;
            }
        };
    }])
    .directive("ngUnique", ['$userModel', function ($userModel) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                element.bind('blur', function (e) {
                    if (!ngModel || !element.val())
                        return;
                    var keyProperty = scope.$eval(attrs.ngUnique);
                    var currentValue = element.val();
                    $userModel.checkUniqueValue(keyProperty.property, currentValue)
                        .then(function (unique) {
                            //Ensure value that being checked hasn't changed
                            //since the Ajax call was made
                            if (currentValue === element.val()) {
                                ngModel.$setValidity('unique', unique.data.isUnique);
                                // scope.$broadcast('show-errors-check-validity');
                            }
                        });
                });
            }
        }
    }])

    .directive("ngExist", ['$userModel', function ($userModel) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                element.bind('blur', function (e) {
                    if (!ngModel || !element.val())
                        return;
                    var keyProperty = scope.$eval(attrs.ngExist);
                    var currentValue = element.val();
                    $userModel.checkExist(keyProperty.property, currentValue)
                        .then(function (exist) {
                            //Ensure value that being checked hasn't changed
                            //since the Ajax call was made
                            if (currentValue === element.val()) {
                                ngModel.$setValidity('exist', exist.data.isUnique);
                                // scope.$broadcast('show-errors-check-validity');
                            }
                        });
                });
            }
        }
    }])

    .directive("ngValid", ['$userModel', '$sce', function ($userModel, $sce) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                element.bind('blur', function (e) {
                    if (!ngModel || !element.val())
                        return;
                    // var keyProperty = scope.$eval(attrs.ngExist);
                    var currentValue = element.val();
                    $userModel.checkOTP(scope.user)
                        .then(function (data) {
                            //Ensure value that being checked hasn't changed
                            //since the Ajax call was  console.log(data);
                            if (data.data.success) {
                                scope.show = false;
                                ngModel.$setValidity('valid', true);
                            } else {
                                scope.show = true;
                                scope.error = 'danger';
                                scope.message = $sce.trustAsHtml(data.data.message);
                                ngModel.$setValidity('valid', false);
                            }
                            // if (currentValue === element.val()) {
                            //     ngModel.$setValidity('valid', data.data.isUnique);
                            //     // scope.$broadcast('show-errors-check-validity');
                            // }
                        });
                });
            }
        }
    }])
    .directive("removeMe", ['$rootScope', function ($rootScope) {
        return {
            link: function (scope, element, attrs) {
                element.bind("click", function () {
                    element.remove();
                });
            }
        }

    }])
    .directive('compareTo', function () {
        return {
            require: "ngModel",
            scope: {
                otherModelValue: "=compareTo"
            },
            link: function (scope, element, attributes, ngModel) {
                ngModel.$validators.compareTo = function (modelValue) {
                    return modelValue == scope.otherModelValue;
                };
                scope.$watch("otherModelValue", function () {
                    ngModel.$validate();
                });
            }
        };

    })

    .directive('ngAutocomplete', ['$parse', function ($parse) {
        function convertPlaceToFriendlyObject(place) {
            var result = undefined;
            if (place) {
                result = {};
                for (var i = 0, l = place.address_components.length; i < l; i++) {
                    if (i == 0) {
                        result.searchedBy = place.address_components[i].types[0];
                    }
                    result[place.address_components[i].types[0]] = place.address_components[i].long_name;
                }
                result.formattedAddress = place.formatted_address;
                result.lat = place.geometry.location.lat();
                result.lng = place.geometry.location.lng();
            }
            return result;
        }

        return {
            restrict: 'A',
            require: 'ngModel',
            link: function ($scope, $element, $attrs, $ctrl) {

                if (!angular.isDefined($attrs.details)) {
                    throw '<ng-autocomplete> must have attribute [details] assigned to store full address object';
                }

                var getDetails = $parse($attrs.details);
                var setDetails = getDetails.assign;
                var getOptions = $parse($attrs.options);

                //options for autocomplete
                var opts;

                //convert options provided to opts
                var initOpts = function () {
                    opts = {};
                    if (angular.isDefined($attrs.options)) {
                        var options = getOptions($scope);
                        if (options.types) {
                            opts.types = [];
                            opts.types.push(options.types);
                        }
                        if (options.bounds) {
                            opts.bounds = options.bounds;
                        }
                        if (options.country) {
                            opts.componentRestrictions = {
                                country: options.country
                            };
                        }
                    }
                };

                //create new autocomplete
                //reinitializes on every change of the options provided
                var newAutocomplete = function () {
                    var gPlace = new google.maps.places.Autocomplete($element[0], opts);
                    google.maps.event.addListener(gPlace, 'place_changed', function () {
                        $scope.$apply(function () {
                            var place = gPlace.getPlace();
                            var details = convertPlaceToFriendlyObject(place);
                            setDetails($scope, details);
                            $ctrl.$setViewValue(details.formattedAddress);
                            $ctrl.$validate();
                        });
                        if ($ctrl.$valid && angular.isDefined($attrs.validateFn)) {
                            $scope.$apply(function () {
                                $scope.$eval($attrs.validateFn);
                            });
                        }
                    });
                };
                newAutocomplete();

//                        $ctrl.$validators.parse = function (value) {
//                            var details = getDetails($scope);
//                            var valid = ($attrs.required == true && details != undefined && details.lat != undefined) ||
//                                    (!$attrs.required && (details == undefined || details.lat == undefined) && $element.val() != '');
//                            return valid;
//                        };

                $element.on('keypress', function (e) {
                    // prevent form submission on pressing Enter as there could be more inputs to fill out
                    if (e.which == 13) {
                        e.preventDefault();
                    }
                });

                //watch options provided to directive
                if (angular.isDefined($attrs.options)) {
                    $scope.$watch($attrs.options, function () {
                        initOpts();
                        newAutocomplete();
                    });
                }

                // user typed something in the input - means an intention to change address, which is why
                // we need to null out all fields for fresh validation
                $element.on('keyup', function (e) {
                    //          chars 0-9, a-z                        numpad 0-9                   backspace         delete           space
                    if ((e.which >= 48 && e.which <= 90) || (e.which >= 96 && e.which <= 105) || e.which == 8 || e.which == 46 || e.which == 32) {
                        var details = getDetails($scope);
                        if (details != undefined) {
                            for (var property in details) {
                                if (details.hasOwnProperty(property) && property != 'formattedAddress') {
                                    delete details[property];
                                }
                            }
                            setDetails($scope, details);
                        }
                        if ($ctrl.$valid) {
                            $scope.$apply(function () {
                                $ctrl.$setValidity('parse', false);
                            });
                        }
                    }
                });
            }
        };
    }])

    .directive('ngToggle', function () {
        return {
            restrict: 'A',
            scope: {
                isOpen: "=ngToggle"
            },
            link: function ($scope, element, attrs) {
                $scope.$watch('isOpen', function (newVal, oldVal) {
                    if (newVal !== oldVal) {
                        element.stop().slideToggle();
                    }
                });
            }
        };
    })
    .directive('smoothScrollJquery', [
        '$log', function ($log) {
            return {
                restrict: 'A',
                link: function ($scope, element, attrs) {
                    return element.bind('click', function () {
                        var offset, speed, target;
                        if (attr.target) {
                            offset = attr.offset || 100;
                            target = $('#' + attr.target);
                            speed = attr.speed || 800;
                            // $log.log('Smooth scroll jQuery: scrolling to', attr.target, 'with offset', offset, 'and speed', speed);
                            return $('html,body').stop().animate({
                                scrollTop: target.offset().top - offset
                            }, speed);
                        } else {
                            $log.log('Smooth scroll jQuery: no target specified, scrolling to top');
                            return $('html,body').stop().animate({
                                scrollTop: 0
                            }, speed);
                        }
                    });
                }
            };
        }
    ])

    .directive("bookNow", function () {
        return {
            restrict: 'E',
            scope: {
                data: '=data',
                type: '=type',
                index: '=index',
                tittle: '=tittle',
                area: '=area'
            },
            controller: ['$scope', '$uibModal', '$cookies', 'base64', '$window', function ($scope, $uibModal, $cookies, base64, $window) {
                //basic handling. It could be delegated to different Services
                $scope.item = angular.extend($scope.data, {type: $scope.type});
                var room_bed_status = parseInt($scope.data.booking_type);
                var booked_bed = parseInt($scope.data.booked_bed);
                var rowItem = parseInt($scope.index) + 1;
                if ($scope.type == 'room' && (booked_bed > 0 || room_bed_status > 1)) {
                    $scope.booked = true;
                }
                if ($scope.type == 'bed' && (booked_bed >= rowItem)) {
                    $scope.booked = true;
                }

                $scope.book = function (row) {
                    var cartItem = {id: row.id, type: row.type, bedno: rowItem}
                    cartItem = base64.encode(JSON.stringify(cartItem));
                    $cookies.put('_ord', cartItem);
                    $window.location.href = '/checkout/init';
                    // $uibModal.open({
                    //     templateUrl: 'templates/property/booknow.html',
                    //     controller: 'bookingCtrl',
                    //     resolve: {
                    //                    //         param: function () {
                    //             var param = angular.extend(row, {
                    //                 tittle: $scope.tittle,
                    //                 area: $scope.area,
                    //                 bedno: rowItem
                    //             });
                    //             return param;
                    //         }
                    //     }
                    //     // windowClass: 'call_back'
                    // });
                }
            }],
            template: '<a href="javascript:void(0);" data-ng-if="!booked" data-ng-click="book(item)" class="btn"> Book Now</span>'
            + '</a><a href="javascript:void(0);" data-ng-if="booked" class="btn booked"> Booked</span> </a>',
        }
    })

    .directive("beds", function () {
        return {
            restrict: 'E',
            scope: {
                data: '=data',
                index: '=index',
            },
            link: function ($scope, element, attrs) {
                var room_bed_status = parseInt($scope.data.booking_type);
                var booked_bed = parseInt($scope.data.booked_bed);
                var rowItem = parseInt($scope.index) + 1;
                if (room_bed_status == 1) {
                    $scope.booked = true;
                }
                if (booked_bed >= rowItem) {
                    $scope.booked = true;
                }
            },
            template: '<i data-ng-if="booked" class="fa fa-bed"></i><i data-ng-if="!booked" class="fa fa-bed green"></i>',
        }
    })
    .directive("reserveBeds", function () {
        return {
            restrict: 'E',
            scope: {
                data: '=data',
                index: '=index',
            },
            link: function ($scope, element, attrs) {
                //basic handling. It could be delegated to different Services
                var room_bed_status = parseInt($scope.data.booking_type);
                var booked_bed = parseInt($scope.data.booked_bed);
                var rowItem = parseInt($scope.index) + 1;
                if (room_bed_status == 1) {
                    $scope.booked = true;
                }
                if (booked_bed >= rowItem) {
                    $scope.booked = true;
                }
            },
            template: '<span data-ng-if="booked"><i class="fa fa-bed gray"></i></span>' +
            '<input data-ng-if="!booked" type="checkbox" data-ng-model="room.selected_bed"> <span data-ng-if="!booked"><i class="fa fa-bed"></i></span>',
        }
    })
    .directive("owlCarousel", function () {
        return {
            restrict: 'E',
            transclude: false,
            link: function (scope) {
                var defaultOptions = {
                    margin: 10,
                    responsive: {
                        0: {items: 1},
                        600: {items: 2},
                        1000: {items: 4}
                    }
                };
                scope.initCarousel = function (element) {
                    // provide any default options you want
                    var customOptions = scope.$eval($(element).attr('data-options'));
                    // combine the two options objects
                    for (var key in customOptions) {
                        defaultOptions[key] = customOptions[key];
                    }
                    // init carousel
                    $(element).owlCarousel(defaultOptions);
                };
            }
        };
    })
    .directive('owlCarouselItem', [function () {
        return {
            restrict: 'A',
            transclude: false,
            link: function (scope, element) {
                // wait for the last item in the ng-repeat then call init
                if (scope.$last) {
                    scope.initCarousel(element.parent());
                }
            }
        };
    }])
    .directive('lightgallery', function () {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                if (scope.$last) {
                    element.parent().lightGallery({
                        selector: '.b-link-stripe'
                    });
                }
            }
        };
    })
    .directive('ngDropzone', ['$timeout', 'dropzoneOps', function ($timeout, dropzoneOps) {
        return {
            restrict: 'AE',
            template: '<div></div>',
            replace: true,
            scope: {
                options: '=?', //http://www.dropzonejs.com/#configuration-options
                callbacks: '=?', //http://www.dropzonejs.com/#events
                methods: '=?' //http://www.dropzonejs.com/#dropzone-methods
            },
            link: function (scope, iElem, iAttr) {
                //Set options for dropzone {override from dropzone options provider}
                scope.options = scope.options || {};
                var initOps = angular.extend({}, dropzoneOps, scope.options);


                //Instantiate dropzone with initOps
                var dropzone = new Dropzone(iElem[0], initOps);


                /*********************************************/


                //Instantiate Dropzone methods (Control actions)
                scope.methods = scope.methods || {};

                scope.methods.getDropzone = function () {
                    return dropzone; //Return dropzone instance
                };

                scope.methods.getAllFiles = function () {
                    return dropzone.files; //Return all files
                };

                var controlMethods = [
                    'removeFile', 'removeAllFiles', 'processQueue',
                    'getAcceptedFiles', 'getRejectedFiles', 'getQueuedFiles', 'getUploadingFiles',
                    'disable', 'enable', 'confirm', 'createThumbnailFromUrl'
                ];

                angular.forEach(controlMethods, function (methodName) {
                    scope.methods[methodName] = function () {
                        dropzone[methodName].apply(dropzone, arguments);
                        if (!scope.$$phase && !scope.$root.$$phase) scope.$apply();
                    }
                });


                /*********************************************/


                //Set invents (callbacks)
                if (scope.callbacks) {
                    var callbackMethods = [
                        'drop', 'dragstart', 'dragend',
                        'dragenter', 'dragover', 'dragleave', 'addedfile', 'removedfile',
                        'thumbnail', 'error', 'processing', 'uploadprogress',
                        'sending', 'success', 'complete', 'canceled', 'maxfilesreached',
                        'maxfilesexceeded', 'processingmultiple', 'sendingmultiple', 'successmultiple',
                        'completemultiple', 'canceledmultiple', 'totaluploadprogress', 'reset', 'queuecomplete'
                    ];
                    angular.forEach(callbackMethods, function (method) {
                        var callback = (scope.callbacks[method] || angular.noop);
                        dropzone.on(method, function () {
                            callback.apply(null, arguments);
                            if (!scope.$$phase && !scope.$root.$$phase) scope.$apply();
                        });
                    });
                }
            }
        }
    }])
    .provider('dropzoneOps', function () {
        /*
         *	Add default options here
         **/
        var defOps = {
            //Add your options here
        };

        return {
            setOptions: function (newOps) {
                angular.extend(defOps, newOps);
            },
            $get: function () {
                return defOps;
            }
        }
    })
    .directive('backButton', function () {
        return {
            restrict: 'A',

            link: function (scope, element, attrs) {
                element.bind('click', goBack);
                function goBack() {
                    history.back();
                    scope.$apply();
                }
            }
        }
    })
    .directive("screenSize", function () {
        return {
            restrict: 'A',
            link: function ($scope, element, attrs) {
                if ($scope.responsive() == 'tiny') {
                    console.log(element.removeAttr('hl-sticky'));
                }
            },

        }
    })
    /*
     * Filters
     */
    .filter('location', function () {
        return function (location, query) {
            var results = [];
            var q = query.toLowerCase();
            angular.forEach(location, function (location) {
                if (location.loc_name.toLowerCase().indexOf(q) !== -1) {
                    results.push(location);
                } else if (location.city_name.toLowerCase().indexOf(q) !== -1) {
                    results.push(location);
                }
            });
            return results;
        };
    })
    .filter('numberFixedLen', function () {
        return function (n, len) {
            var num = parseInt(n, 10);
            len = parseInt(len, 10);
            if (isNaN(num) || isNaN(len)) {
                return n;
            }
            num = '' + num;
            while (num.length < len) {
                num = '0' + num;
            }
            return num;
        };
    })
    .filter('capitalize', function () {
        return function (input) {
            return (!!input) ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : '';
        }
    })
    .filter('camelCase', function () {
        return function (input) {
            input = input || '';
            return input.replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        };
    })
    .filter('setDecimal', ['$filter', function () {
        return function (input, places) {
            if (isNaN(input)) return input;
            // If we want 1 decimal place, we want to mult/div by 10
            // If we want 2 decimal places, we want to mult/div by 100, etc
            // So use the following to create that factor
            var factor = "1" + Array(+(places > 0 && places + 1)).join("0");
            return Math.round(input * factor) / factor;
        };
    }])

    .filter('topImage', ['$CONFIG', '$filter', function ($CONFIG, $filter) {
        return function (input) {
            if (input.length > 0) {
                input = $filter('orderBy')(input, 'ordering');
                return $CONFIG.IMAGE_URL + 'properties/' + input.slice(0)[0].pro_id + '/medium/' + input.slice(0)[0].image;
            } else {
                return $CONFIG.NOT_AVAIL_IMAGE;
            }
        };
    }])

    .filter('imageUrl', ['$CONFIG', '$filter', function ($CONFIG, $filter) {
        return function (input) {
            if (input) {
                return $CONFIG.IMAGE_URL + 'properties/' + input.pro_id + '/medium/' + input.image;
            } else {
                return $CONFIG.NOT_AVAIL_IMAGE;
            }
        };
    }])
    .filter('urlSlug', ['$CONFIG', '$filter', function ($CONFIG, $filter) {
        return function (data) {
            var url;
            if (data.accom_type == 'Sharing') {
                url = data.accom_type + ' ' + data.furnished + ' ' + data.category + ' ' + 'for' + ' ' + 'rent' + ' ' + 'in' + ' ' + data.location + ' ' + data.city + ' ' + data.id
            } else {
                url = data.bedroom + ' ' + data.furnished + ' ' + data.category + ' ' + 'for' + ' ' + 'rent' + ' ' + 'in' + ' ' + data.location + ' ' + data.city + ' ' + data.id;
            }
            return $filter('slugify')(url);

            // if (input.photo) {
            //     return $CONFIG.IMAGE_URL + input.id + '/medium/' + input.photo;
            // } else {
            //     return $CONFIG.NOT_AVAIL_IMAGE;
            // }
        };
    }])
    .filter('parse', function () {
        return function (input) {
            if (input)
                return angular.fromJson(input);
        }
    })
    .filter('ordinal', function () {
        return function (input) {
            if (parseInt(input) > 0) {
                var s = ["th", "st", "nd", "rd"],
                    v = input % 100;
                return input + "<sup>" + (s[(v - 20) % 10] || s[v] || s[0]) + "</sup>";
            } else {
                return "Ground";
            }
        }
    })
    // .filter('parse_room', function () {
    //     return function (input) {
    //         if (input) {
    //             var item = angular.fromJson(input);
    //             if (item.length > 0) {
    //                 var room = item[0].split('-')[0] + '-' + item[item.length - 1].split('-')[0];
    //                 var size = item[0].split('-')[1] + '-' + item[item.length - 1].split('-')[1];
    //                 return '<p>' + room + '</p><p>' + size + ' Sq.ft. </p>';
    //             }
    //         }
    //     }
    // })
    .filter('parse_room', function () {
        return function (input) {
            if (input) {
                if (input.length > 0) {
                    var room = input[0].bedroom.category_name + '-' + input[input.length - 1].bedroom.category_name;
                    var size = parseInt(input[0].size) + '-' + parseInt(input[input.length - 1].size);
                    return '<p>' + room + '</p><p>' + size + ' Sq.ft. </p> <p class="rent"><span>Average Rent :</span> <i class="fa fa-inr"></i> ' + parseInt(input[0].rent_price) + ' &nbsp; - &nbsp;<i class="fa fa-inr"></i> ' + parseInt(input[input.length - 1].rent_price) + '</p>';
                }
            }
        }
    })
    .filter('locImageUrl', ['$CONFIG', function ($CONFIG) {
        return function (input) {
            if (input) {
                return $CONFIG.IMAGE_URL + 'location/' + input.loc_id + '/thumb/' + input.image;
            } else {
                return $CONFIG.NOT_AVAIL_IMAGE;
            }
        };
    }])

    .filter('socImageUrl', ['$CONFIG', function ($CONFIG) {
        return function (input) {
            if (input) {
                return $CONFIG.IMAGE_URL + 'society/' + +input.sid + '/thumb/' + input.image;
            } else {
                return $CONFIG.NOT_AVAIL_IMAGE;
            }
        };
    }])
    .filter('split', function () {
        return function (input, splitChar, splitIndex) {
            // do some bounds checking here to ensure it has that index
            return input.split(splitChar)[splitIndex];
        }
    })
    .filter('replace_underscore', function () {
        return function (input) {
            if (input) {
                return input.split("-").join(" ");
            }
        }
    })