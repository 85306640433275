var app = angular.module('app', ['ui.router', 'ui.bootstrap', 'ngCookies', 'ngMaterial', 'ngAria', 'ngAnimate', 'ngMessages', 'ngSanitize', 'leaflet-directive', 'lfNgMdFileInput', 'googleplus', 'slugifier', 'ab-base64', 'ngProgress', 'md.data.table', 'rzModule', 'ui.bootstrap.datetimepicker', 'hl.sticky'], ['$interpolateProvider', function ($interpolateProvider) {
    $interpolateProvider.startSymbol('<%');
    $interpolateProvider.endSymbol('%>');
}]);
app.constant('$CONFIG', {
    'NOT_AVAIL_IMAGE': 'images/nopropertyphoto.png',
    'IMAGE_URL': 'images/osproperty/',
    'PROPERTY_NOT_FOUND': "<div ng-cloak data-ng-if='!total' class='col-md-12 not-found row-space-2 text-center'><figure><img src='images/not-founds.png' alt='Property not found'/></figure><h3>Didn't find property in this loacality !!</h3><p>We'll keep you posted when more properties come up.</p></div>",
    // Filter category Parent Id
    'PROPERTY_TYPE_ID': 16,
    'FURNISHED_TYPE_ID': 17,
    'ACCOMMODATION_TYPE_ID': 21,
    'SHARING_TYPE_ID': 25,
    'ROOMS_TYPE_ID': 28,
    'LEASE_TYPE_ID': 35,
    'GENDER_TYPE_ID': 39,
    'TENANT_TYPE_ID': 42,
    'AVAIL_FROM_ID': 46,
    'PROPERTY_AGE_ID': 52,
    'LOCATION_TYPE_ID': 64,
    'BKCANCEL_PARENT_ID': 57,
    // End
    // Filter Conveninece  Parent Id
    'FLAT_AMENITIES': 6,
    'COMMON_AMENITIES': 0,
    'PLAY_AMENITIES': 9,
    'SERVICE_AMENITIES': 2,
    // End

    'DELHI_ID': 2,
    'DELHI_LAT': parseFloat(28.613939),
    'DELHI_LNG': parseFloat(77.209021),
    'NOIDA_ID': 3,
    'NOIDA_LAT': parseFloat(28.53552),
    'NOIDA_LNG': parseFloat(77.39103),
    'GURGAON_ID': 5,
    'GURGAON_LAT': parseFloat(28.45950),
    'GURGAON_LNG': parseFloat(77.02664),
    // Default property value
    'D_CITY_ID': 3,
    'D_BEDROOM_ID': 30,
    'D_ACCOMMODATION_ID': 22,
    'D_BATHROOM_ID': 1,
    'D_FURNISHED_ID': 20,
    'D_TEANAT_PREFRENCE_ID': 45,
    'D_GENDER_PREFRENCE': 40,
    'D_LEASE_TYPE_ID': 36,
    'D_RENT_STATUS': "0",

    // Default Map value
    'D_LAT': parseFloat(28.53552),
    'D_LNG': parseFloat(77.39103),
    'D_MAP_CIRCLE_RADIUS': parseFloat(15000),
    'D_MAP_ZOOM': 13,
    'D_MARKER_ICON': '../../images/leaflet/marker.png',
    'D_MARKER_ICON_SIZE': [37.2, 61],
    'D_MARKER_DRAG_MSG': "Drag me to get exact location",
    'D_MARKER_DRAG': true,
    'DATE_FORMAT': 'dd/MM/yyyy',
    'DATE_PATTERN': '/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/i',
    'CONTACT_PATTERN': '/^\+?\d[0-9-]{9,20}/',
})
    .constant('$API', {
        /*
         * LOGIN REGISTRATION API
         * */
        'API_POST_CHECK_UNIQUE': baseUrl + 'api/v1/auth/isunique',
        'API_POST_REGISTER': baseUrl + 'api/v1/auth/register',
        'API_POST_LOGIN': baseUrl + 'api/v1/auth/login',
        'POST_CRM': 'http://www.101lease.com/crm/modules/Webforms/capture.php?callback=JSON_CALLBACK&',
        'CRM_POST': baseUrl + 'api/v1/crm',
        // 'REV_POST_CRM': 'https://www.101lease.com/rqrmnt/postdata.php?',
        'REV_POST_CRM': baseUrl + 'rqrmnt/api/v1/postdata?',

        'API_POST_SOCIAL_LOGIN': baseUrl + 'api/v1/auth/social',
        'API_POST_RESET_REQUEST': baseUrl + 'api/v1/auth/reset/',
        'API_POST_CHECK_OTP': baseUrl + 'api/v1/auth/otp',
        'API_POST_RESET_COMPLETE': baseUrl + 'api/v1/auth/reset',
        'API_POST_RESET_MAIL': baseUrl + 'api/v1/auth/resent',
        'API_POST_AUTH_CHECKOUT': baseUrl + 'api/v1/auth/checkout',
        /*
         * Property API
         * GET METHOD
         * */
        'API_GET_USER_PROFILE': baseUrl + 'api/v1/auth/user/profile',
        'API_GET_STATE': baseUrl + 'api/v1/state',
        'API_GET_CITY': baseUrl + 'api/v1/city',
        'API_GET_AUTO_LOCATION': baseUrl + 'api/v1/location',
        'API_GET_CATEGORY': baseUrl + 'api/v1/category?type=',
        'API_GET_CONNECTIVITY': baseUrl + 'api/v1/connectivity',
        'API_GET_AMENITY': baseUrl + 'api/v1/auth/amenities',
        'API_GET_USER_PROPERTY': baseUrl + 'api/v1/auth/user/property?',
        'API_GET_ROOMS': baseUrl + 'api/v1/auth/property/rooms/',
        'API_GET_BOOKINGS': baseUrl + 'api/v1/auth/room/bookings/',
        'API_GET_SEARCH_PROPERTY': baseUrl + 'api/v1/property/search?',
        'API_GET_PROPERTY_DETAILS': baseUrl + 'api/v1/property/overview/',
        'API_GET_SIMILAR_PROPERTY': baseUrl + 'api/v1/property/similar/',
        'API_GET_RECOMMENDED_PROPERTY': baseUrl + 'api/v1/property/recommend?',
        'API_GET_MY_BOOKINGS': baseUrl + 'api/v1/auth/user/booking?',
        'API_GET_REFERENCE_URL': baseUrl + 'api/v1/auth/property/reference?',
        /*
         *GET NEIGHBOURHOOD LOCATION & SOCIETY
         * */
        'API_GET_LOCATION': baseUrl + 'api/v1/auth/location',
        'API_GET_SOCIETY': baseUrl + 'api/v1/auth/society',
        'API_GET_SOCIETY_UNITS': baseUrl + 'api/v1/auth/society/units/',
        // 'GET_SOCIETY_EDIT': baseUrl + 'api/v1/auth/society/',


        /*
         * POST METHOD
         * */
        'API_POST_PROPERTY': baseUrl + 'api/v1/auth/add/property',
        'API_POST_PROPETRY_AMENITY': baseUrl + 'api/v1/auth/property/amenities',
        'API_POST_PROPERTY_CHANNEL_PARTNER': baseUrl + 'api/v1/auth/property/partner',
        'API_POST_PROPERTY_IMAGE': baseUrl + 'api/v1/auth/property/image',
        'POST_PROPERTY_IMAGE': baseUrl + 'auth/property/image',
        'API_POST_EDIT_PROPERTY': baseUrl + 'api/v1/auth/property/edit',
        'API_POST_USER_PROFILE': baseUrl + 'api/v1/auth/user/profile',
        'API_POST_CHANGE_PASSWORD': baseUrl + 'api/v1/auth/user/password',
        'API_POST_TENANT_MAIL': baseUrl + 'api/v1/tenantmail',
        'API_POST_OWNER_MAIL': baseUrl + 'api/v1/ownermail',
        'API_POST_PROPERTY_STATUS': baseUrl + 'api/v1/auth/property/publish/',
        'API_POST_CATEGORY': baseUrl + 'api/v1/category',
        'API_POST_BOOKING': baseUrl + 'api/v1/auth/book',
        'API_POST_RESERVE_ROOM': baseUrl + 'api/v1/auth/booking/reserve',
        'API_POST_BOOKING_CANCEL': baseUrl + 'api/v1/auth/booking/cancel',
        'API_POST_BOOKING_TICKET': baseUrl + 'api/v1/auth/user/booking/receipt',
        'API_POST_CART_ITEM': baseUrl + 'api/v1/cart/items',
        'API_POST_TINY_URL': baseUrl + 'api/v1/auth/property/urlshortener',
        'API_POST_REFERENCE_URL': baseUrl + 'api/v1/auth/property/reference',
        /*
         *POST NEIGHBOURHOOD LOCATION & SOCIETY
         * */
        'API_POST_LOCATION': baseUrl + 'api/v1/auth/location',
        'POST_LOCATION_IMAGE': baseUrl + 'auth/location/image',
        'API_POST_LOCATION_IMAGE': baseUrl + 'api/v1/auth/location/image',
        'API_POST_SOCIETY': baseUrl + 'api/v1/auth/society',
        'POST_SOCIETY_IMAGE': baseUrl + 'auth/society/image',
        'API_POST_SOCIETY_IMAGE': baseUrl + 'api/v1/auth/society/image',
        'API_POST_SOCIETY_UNIT': baseUrl + 'api/v1/auth/society/unit',
        'API_POST_UNIT_PRICE': baseUrl + 'api/v1/auth/society/unit/price',

        'API_POST_LOCATION_INFO': baseUrl + 'api/v1/neighbourhood/location/',
        'API_POST_SOCIETY_INFO': baseUrl + 'api/v1/neighbourhood/society/',
        /*
         *  CRM API
         * */
        'API_AUTH_CRM': 'http://revcrm.local/api/v1/webregister',
        'API_AUTH_EXE_LEADS': 'http://revcrm.local/api/v1/getdata',
        'API_AUTH_REQUIREMENT': 'http://revcrm.local/api/v1/getdata/',
        'API_AUTH_TENANT_INFO': 'http://revcrm.local/api/v1/gettenant',
        'API_AUTH_GET_ALL_MASTER': 'http://revcrm.local/api/v1/getallmaster',
        'API_AUTH_GET_EXECUTIVE': 'http://revcrm.local/api/v1/executive',
        'API_AUTH_SAVE_REQUIREMENT': 'http://revcrm.local/api/v1/updaterqrmnt',
    })
app.value('psResponsiveConfig', {
    sizes: [{
        name: 'tiny',
        minWidth: 0
    }, {
        name: 'small',
        minWidth: 768
    }, {
        name: 'medium',
        minWidth: 992
    }, {
        name: 'large',
        minWidth: 1200
    }]
})
// make back button handle closing the modal
app.run(['$rootScope', '$uibModalStack', '$uibModal', '$state', '$stateParams', '$userModel', '$window', '$CONFIG', '$cookies', '$interval', '$timeout', '$document', 'ngProgressFactory', 'psResponsive',
    function ($rootScope, $uibModalStack, $uibModal, $state, $stateParams, $userModel, $window, $CONFIG, $cookies, $interval, $timeout, $document, ngProgressFactory, psResponsive) {
        if (!$cookies.get('l_ss')) {
            $cookies.put('l_ss', 1);
            $rootScope.loader = true;
            $timeout(function () {
                $rootScope.loader = false;
            }, 2500);
        } else {
            $rootScope.loader = true;
            $timeout(function () {
                $rootScope.loader = false;
            }, 25);
        }
        $rootScope.isSticky = psResponsive() == 'tiny' ? false : true;
        $rootScope.$state = $state;
        $rootScope.loginLink = pstate + '.login';
        // $rootScope.$stateParams = $stateParams;
        $rootScope.previousState = 'home';
        $rootScope.authcheck = false;
        $rootScope.agent = false;
        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            $rootScope.previousState = fromState.name;
            $rootScope.currentState = toState.name;
            $rootScope.previousState_params = toParams;
            $rootScope.progressbar.complete();

        });

        $rootScope.back = function () {
            $state.go($rootScope.previousState, $rootScope.previousState_params);
        };
        $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
            $rootScope.progressbar.setColor('#03A9F4');
            $rootScope.progressbar.start();
            if (toState.authenticate && !$userModel.isAuthenticated()) {
                $userModel.logout();
                event.preventDefault();
            }
            if ($userModel.isAuthenticated()) {
                $rootScope.authcheck = true;
                $rootScope.authuser = $userModel.getUserObject();
                $rootScope.authToken = $userModel.getUserToken();
            }

            var top = $uibModalStack.getTop();
            if (top) {
                $uibModalStack.dismiss(top.key);
            }
        });
        $rootScope.progressBar = function (status) {
            var j = 0, counter = 0;
            $rootScope.submit = status;
            $rootScope.mode = 'buffer';
            $rootScope.activated = status;
            $rootScope.determinateValue = 30;
            $rootScope.determinateValue2 = 30;
            $rootScope.modes = [];
            $interval(function () {
                if ($rootScope.activated) {
                    $rootScope.determinateValue += 1;
                    $rootScope.determinateValue2 += 1.5;
                    if ($rootScope.determinateValue > 100)
                        $rootScope.determinateValue = 30;
                    if ($rootScope.determinateValue2 > 100)
                        $rootScope.determinateValue2 = 30
                    // Incrementally start animation the five (5) Indeterminate,
                    // themed progress circular bars
                    if ((j < 2) && !$rootScope.modes[j]) {
                        $rootScope.modes[j] = (j == 0) ? 'buffer' : 'query';
                    }
                }
            }, 100, 0, true);
        }
        $rootScope.popMessage = function (data) {
            var parentElem = data.hasOwnProperty('parentSelector') ?
                angular.element($document[0].querySelector(data.parentSelector)) : undefined;
            if (data.popup) {
                var modalInstance = $uibModal.open({
                    animation: true,
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    template: '<div class="modal-body" id="modal-body"><div class="alert alert-danger text-center" role="alert">' + data.info + '</div><div class="text-center"> <a  class="btn btn-lg btn-info" href="' + data.route + '" target="_self"> Back</a></div> </div>',
                    size: 'sm',
                    appendTo: parentElem,
                    backdrop: false,
                    keyboard: false,
                });
            } else {
                $rootScope.error_msg = '<div class="alert alert-danger text-center" role="alert">' + data.info + '</div><div class="text-center">' +
                    '<a class="btn btn-lg btn-info" href="' + data.route + '" target="_self">Back</a></div></div>';
            }
        }
        $rootScope.$on('$stateChangeError', function () {
            $rootScope.progressbar.complete();
        });

    }]);

window.fbAsyncInit = function () {
    FB.init({
        appId: '1712915255598743',
        xfbml: true,
        version: 'v2.8'
    });
};

(function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
        return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = "//connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));

//(function () {
//    var po = document.createElement('script');
//    po.type = 'text/javascript';
//    po.async = true;
//    po.src = 'https://apis.google.com/js/client:platform.js?onload=start';
//    var s = document.getElementsByTagName('script')[0];
//    s.parentNode.insertBefore(po, s);
//})();
//function start() {
//    gapi.load('auth2', function () {
//        auth2 = gapi.auth2.init({
//            client_id: '656433725378-1qv33glo1jf9i51g0o1mvaliqchcig95.apps.googleusercontent.com',
//            // Scopes to request in addition to 'profile' and 'email'
//            //scope: 'additional_scope'
//        });
//    });
//}
app.provider('showErrorsConfig', function () {
    var _showSuccess;
    _showSuccess = false;
    this.showSuccess = function (showSuccess) {
        return _showSuccess = showSuccess;
    };
    this.$get = function () {
        return {showSuccess: _showSuccess};
    };
});

app.config(['GooglePlusProvider', function (GooglePlusProvider) {
    GooglePlusProvider.init({
        clientId: '656433725378-p7o375k0k4a9vpj5t5u5q57ugttcric9.apps.googleusercontent.com',
        request_uri: '//www.101lease.com',
        apiKey: 'AIzaSyA8JWTKFW81EIKGjmJ4Lu7be-wmWpMcfCM',
        scopes: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
    });
}]);

app.config(['$httpProvider', function ($httpProvider) {
    $httpProvider.interceptors.push('AuthIntercepter');
}]);
