app

    .factory('$userModel', ['$http', '$cookies', '$API', 'base64', '$httpParamSerializer', '$window', function ($http, $cookies, $API, base64, $httpParamSerializer, $window) {
        var $userModel = {};
        var isAuthenticated = false;
        var authToken;
        var authUser;
        $userModel.checkUniqueValue = function (property, value) {
            return $http({
                headers: {
                    'Content-Type': 'application/json'
                },
                url: $API.API_POST_CHECK_UNIQUE,
                method: 'POST',
                data: {
                    property: property,
                    value: value
                }
            }).success(function (response) {
                if (response.status_code === 200) {
                    return response.isUnique;
                }
            }).error(function (data, status, headers) {
                console.log(data);
                return data;
            });
        };
        $userModel.checkExist = function (property, value) {
            return $http({
                headers: {
                    'Content-Type': 'application/json'
                },
                url: $API.API_POST_CHECK_UNIQUE,
                method: 'POST',
                data: {
                    property: property,
                    value: value
                }
            }).success(function (response) {
                if (response.status_code === 200) {
                    if (response.isUnique) {
                        return response.isUnique = false;
                    } else {
                        return response.isUnique = true;
                    }
                }
            }).error(function (data, status, headers) {
                console.log(data);
                return data;
            });
        };
        $userModel.checkOTP = function (data) {
            return $http({
                headers: {
                    'Content-Type': 'application/json'
                },
                url: $API.API_POST_CHECK_OTP,
                method: 'POST',
                data: data,
            }).success(function (response) {
                return response;
            }).error(function (data, status, headers) {
                console.log(data);
                return data;
            });
        };

        $userModel.checkoutAuthentication = function (data) {
            return $http({
                headers: {
                    'Content-Type': 'application/json'
                },
                url: $API.API_POST_AUTH_CHECKOUT,
                method: 'POST',
                data: data,
            }).success(function (response) {
                return response;
            }).error(function (data, status, headers) {
                if (data.status_code === 422) {
                    angular.forEach(data.errors, function (value, key) {
                        alert(key + ': ' + value);
                    });
                }
                if (data.status_code === 500) {
                    alert(data.message);
                }
                return data;
            });
        };
        $userModel.register = function (data) {
            return $http({
                headers: {
                    'Content-Type': 'application/json'
                },
                url: $API.API_POST_REGISTER,
                method: 'POST',
                data: data,
            }).success(function (response) {
                return response;
            }).error(function (data, status, headers) {
                if (data.status_code === 422) {
                    angular.forEach(data.errors, function (value, key) {
                        alert(key + ': ' + value);
                    });
                }
                if (data.status_code === 500) {
                    alert(data.message);
                }
                return data;
            });
        };
        $userModel.verify = function (data) {
            return $http({
                headers: {
                    'Content-Type': 'application/json'
                },
                url: $API.API_POST_REGISTER,
                method: 'POST',
                data: data,
            }).success(function (response) {
                return response;
            }).error(function (data, status, headers) {
                if (data.status_code === 422) {
                    angular.forEach(data.errors, function (value, key) {
                        alert(key + ': ' + value);
                    });
                }
                if (data.status_code === 500) {
                    alert(data.message);
                }
                return data;
            });
        };

        $userModel.login = function (data) {
            return $http({
                headers: {
                    'Content-Type': 'application/json'
                },
                url: $API.API_POST_LOGIN,
                method: 'POST',
                data: data,
            }).success(function (response) {
                if (response.success) {
                    storeUserCredential(response);
                }
                return response;
            }).error(function (data, status, headers) {
                if (data.status_code === 422) {
                    angular.forEach(data.errors, function (value, key) {
                        alert(key + ': ' + value);
                    });
                }
                if (data.status_code === 500) {
                    alert(data.message);
                }
                return data;
            });
        };
        $userModel.resetRequest = function (data) {
            return $http({
                headers: {
                    'Content-Type': 'application/json'
                },
                url: $API.API_POST_RESET_REQUEST + data.email,
                method: 'POST',
                data: data,
            }).success(function (response) {
                return response;
            }).error(function (data, status, headers) {
                if (data.status_code === 422) {
                    angular.forEach(data.errors, function (value, key) {
                        alert(key + ': ' + value);
                    });
                }
                if (data.status_code === 500) {
                    alert(data.message);
                }
                return data;
            });
        };

        $userModel.resetProcess = function (data) {
            return $http({
                headers: {
                    'Content-Type': 'application/json'
                },
                url: $API.API_POST_RESET_COMPLETE,
                method: 'POST',
                data: data,
            }).success(function (response) {
                return response;
            }).error(function (data, status, headers) {
                if (data.status_code === 422) {
                    angular.forEach(data.errors, function (value, key) {
                        alert(key + ': ' + value);
                    });
                }
                if (data.status_code === 500) {
                    alert(data.message);
                }
                return data;
            });
        };

        $userModel.resentMail = function (data) {
            return $http({
                headers: {
                    'Content-Type': 'application/json'
                },
                url: $API.API_POST_RESET_MAIL,
                method: 'POST',
                data: data,
            }).success(function (response) {
                return response;
            }).error(function (data, status, headers) {
                if (data.status_code === 422) {
                    angular.forEach(data.errors, function (value, key) {
                        alert(key + ': ' + value);
                    });
                }
                if (data.status_code === 500) {
                    alert(data.message);
                }
                return data;
            });
        };
        $userModel.crmPost = function (data) {
            return $http({
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                url: $API.POST_CRM + data,
                method: 'jsonp',
            }).success(function (response) {
                return response;
            }).error(function (data, status, headers) {
                console.log(data);
                return data;
            });
        };

        $userModel.postCrm = function (data) {
            return $http({
                headers: {
                    'Content-Type': 'application/json'
                },
                url: $API.CRM_POST,
                method: 'POST',
                data: data,
            }).success(function (response) {
                return response;
            }).error(function (data, status, headers) {
                console.log(data);
                return data;
            });
        };

        $userModel.reverseCrmPost = function (data) {
            return $http({
                headers: {
                    'Content-Type': 'application/json'
                },
                url: $API.REV_POST_CRM + data,
                method: 'GET',
            }).success(function (response) {
                return response;
            }).error(function (data, status, headers) {
                console.log(data);
                return data;
            });
        };
        $userModel.tenantMail = function (data) {
            return $http({
                headers: {
                    'Content-Type': 'application/json'
                },
                url: $API.API_POST_TENANT_MAIL,
                data: data,
                method: 'POST',
            }).success(function (response) {
                return response;
            }).error(function (data, status, headers) {
                console.log(data);
                return data;
            });
        };
        $userModel.ownerMail = function (data) {
            return $http({
                headers: {
                    'Content-Type': 'application/json'
                },
                url: $API.API_POST_OWNER_MAIL,
                data: data,
                method: 'POST',
            }).success(function (response) {
                return response;
            }).error(function (data, status, headers) {
                console.log(data);
                return data;
            });
        };
        $userModel.cities = function () {
            return $http({
                headers: {
                    'Content-Type': 'application/json'
                },
                url: $API.API_GET_CITY,
                method: 'GET',
            }).success(function (response) {
                return response;
            }).error(function (data, status, headers) {
                console.log(data);
                return data;
            });
        };
        $userModel.autoLocation = function (data) {
            return $http({
                headers: {
                    'Content-Type': 'application/json'
                },
                url: $API.API_GET_AUTO_LOCATION,
                method: 'GET',
                params: data
            }).success(function (response) {
                return response;
            }).error(function (data, status, headers) {
                console.log(data);
                return data;
            });
        };
        $userModel.fbLogin = function (data) {
            return $http({
                headers: {
                    'Content-Type': 'application/json'
                },
                url: $API.API_POST_SOCIAL_LOGIN,
                data: data,
                method: 'POST',
            }).success(function (response) {
                return response;
            }).error(function (data, status, headers) {
                console.log(data);
                return data;
            });
        };

        $userModel.socialLogin = function (data) {
            return $http({
                headers: {
                    'Content-Type': 'application/json'
                },
                url: $API.API_POST_SOCIAL_LOGIN,
                data: data,
                method: 'POST',
            }).success(function (response) {
                if (response.success) {
                    storeUserCredential(response);
                }
                // console.log(response);
                // var authData = {
                //     user: response.user,
                //     token: response.key,
                // };
                // $cookies.put('auth', JSON.stringify(authData));
                return response;
            }).error(function (data, status, headers) {
                console.log(data);
                return data;
            });
        };

        $userModel.categories = function (type) {
            return $http({
                headers: {
                    'Content-Type': 'application/json'
                },
                url: $API.API_GET_CATEGORY + type,
                method: 'GET',
            }).success(function (response) {
                return response;
            }).error(function (data, status, headers) {
                return console.log(data);
            });
        };

// $userModel.getPropertyDetails = function (pid) {
//     return $http({
//         headers: {
//             'Content-Type': 'application/json'
//         },
//         url: $API.GET_PROPERTY_DETAILS + pid,
//         method: 'GET'
//     }).success(function (response) {
//         return response;
//     }).error(function (data, status, headers) {
//         return data;
//     });
// }
        $userModel.getRecommendedProperty = function ($params) {
            var param = angular.element.param($params);
            return $http({
                headers: {
                    'Content-Type': 'application/json'
                },
                url: $API.API_GET_RECOMMENDED_PROPERTY + param,
                method: 'GET'
            }).success(function (response) {
                return response;
            }).error(function (data, status, headers) {
                return data;
            });
        };


// /**
//  * Return whether the user is logged in or not
//  * based on the cookie set during the login
//  *
//  * @return {boolean}
//  */
// $userModel.getAuthStatus = function () {
//     var status = $cookies.get('auth');
//     if (status) {
//         return true;
//     } else {
//         return false;
//     }
// };
// /**
//  * Get the user object converted from string to JSON
//  * @return {user object}
//  */
// $userModel.getUserObject = function () {
//     var userObj = angular.fromJson($cookies.get('auth'));
//     return userObj;
// }

        /**
         * Return whether the user is logged in or not
         * based on the cookie set during the login
         *
         * @return {boolean}
         */
        $userModel.isAuthenticated = function () {
            loadUserCredential();
            if (isAuthenticated) {
                return true
            }
            return false;
        };

// $userModel.loadUserCredential = function () {
//
// }

// /**
//  * Get the user object converted from string to JSON
//  * @return {user object}
//  */
        $userModel.getUserObject = function () {
            if (isAuthenticated) {
                return authUser;
            }
        };
        $userModel.getUserToken = function () {
            if (isAuthenticated) {
                return authToken;
            }
        };

        /**
         * Close the session of the current user
         * and delete the cookie set for him
         *
         * @return boolean
         */
        $userModel.logout = function () {
            destroyUserCredential();
        };


// /**
//  * Close the session of the current user
//  * and delete the cookie set for him
//  *
//  * @return boolean
//  */
// $userModel.doUserLogout = function () {
//     $cookies.remove('auth');
// };

        function storeUserCredential(data) {
            var token = base64.encode(data.key.token);
            data = base64.encode(JSON.stringify(data.user));
            var now = new Date();
            var expireDate = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate());
            $cookies.put('_lt', token, {expires: expireDate});
            $cookies.put('c_user', data, {expires: expireDate});
            userCredential(token, data);
        }

        function userCredential(token, data) {
            isAuthenticated = true;
            authToken = base64.decode(token);
            authUser = JSON.parse(base64.decode(data));
        }

        function loadUserCredential() {
            var token = $cookies.get('_lt');
            if (token) {
                var data = $cookies.get('c_user');
                userCredential(token, data);
            }
        }

        function destroyUserCredential($http) {
            authToken = undefined;
            isAuthenticated = false;
            authUser = undefined;
            // $http.defaults.headers.common['Authorization'] = undefined;
            $cookies.remove('_lt');
            $cookies.remove('c_user');
        }

        return $userModel;
    }])

    .factory('$cartModel', ['$http', '$API', '$window', function ($http, $API, $window) {
        var $cartModel = {};
        $cartModel.getCartItem = function (data) {
            return $http({
                headers: {
                    'Content-Type': 'application/json'
                },
                url: $API.API_POST_CART_ITEM,
                method: 'POST',
                data: data,
            }).success(function (response) {
                return response;
            }).error(function (data, status, headers) {
                $window.location.href = 'error/' + data.status_code;
                return data;
            });
        };

        return $cartModel;
    }])
    .factory('$ticketModel', ['$http', '$API', '$window', function ($http, $API, $window) {
        var $ticketModel = {};
        $ticketModel.loadTicket;
        $ticketModel.createTicket = function (data) {
            return $http({
                headers: {
                    'Content-Type': 'application/json'
                },
                url: $API.API_POST_BOOKING_TICKET,
                method: 'POST',
                data: data,
            }).success(function (response) {
                // if (response.success) {
                //     $detailModel.loadPropertyDetails = response;
                // }
                return response;
            }).error(function (data, status, headers) {
                if (data.status_code === 500) {
                    // alert(data.message);
                    $window.location.href = 'error/' + data.status_code;
                }
                // console.log(data);
                return data;
            });
        };

        return $ticketModel;
    }])
    .factory('AuthIntercepter', ['$rootScope', '$q', '$CONFIG', '$window', '$cookies', function ($rootScope, $q, $CONFIG, $window, $cookies) {
        return {
            // request: function (config) {
            //     var token = $cookies.get('_lt');
            //     if (token) {
            //         config.headers['Authorization'] = 'bearer:' + token;
            //     }
            //     // if ($userModel.isAuthenticated()) {
            //     //     // var token = $cookies.get('lu');
            //     //     // $http.defaults.headers.common['Authorization'] = 'bearer:' + token;
            //     //     // config.headers['Authorization'] = SessionService.token;
            //     // }
            //     return config;
            // },
            // response: function (response) {
            //     if (response.data.success) {
            //         if (response.data.hasOwnProperty('key')) {
            //             var token = response.data.key.token;
            //             $cookies.put('_lt', token);
            //         }
            //     }
            //     return response;
            // },
            responseError: function (response) {
                if (response.status == 401) {
                    $cookies.remove('_lt');
                    $cookies.remove('c_user');
                    $window.location.href = '/logout';
                    // $rootScope.$broadcast({401: $CONFIG.NOT_AUTHENTICATED,}[response.status], response);
                }
                return $q.reject(response);
            }
        };
    }])

    .factory('psResponsive', ['$window', '$filter', '$rootScope', 'psResponsiveConfig',
        function ($window, $filter, $rootScope, psResponsiveConfig) {
            var opRegEx = /[<>]=?\s\w+/,
                forEach = angular.forEach,
                filter = angular.filter,
                sizes = psResponsiveConfig.sizes;

            sizes = $filter('orderBy')(sizes, '-minWidth');

            var getHeight = function () {
                    return $window.innerHeight;
                },

                getWidth = function () {
                    return $window.innerWidth;
                },

                getLabel = function () {
                    var cWidth = getWidth(),
                        returnVal = false;
                    for (var i = 0; i < sizes.length; i++) {
                        if (parseInt(cWidth) >= parseInt(sizes[i].minWidth)) {
                            return sizes[i].name;
                        }
                    }
                },
                getWidthFromLabel = function (label) {
                    return $filter('filter')(sizes, {
                        name: label
                    })[0]["minWidth"];
                },

                getTest = function (test) {
                    var thingy = test.split(' ')[0],
                        against = test.split(' ')[1];

                    if (isNaN(against)) {
                        return eval('(' + getWidth() + ' ' + thingy + ' ' + getWidthFromLabel(against) + ')');
                    } else {
                        return eval('(' + getWidth() + thingy + parseInt(against) + ')');
                    }
                },

                getOrientation = function () {
                    if (getHeight() > getWidth()) return 'portrait';
                    else return 'landscape';
                };

            angular.element($window).on('resize', function () {
                $rootScope.$digest();
            });

            return function (onwha) {
                if (!onwha) {
                    return getLabel();
                } else if (onwha == 'width') {
                    return getWidth();
                } else if (onwha == 'height') {
                    return getHeight();
                } else if (onwha == 'orientation') {
                    return getOrientation();
                } else if (opRegEx.test(onwha)) {
                    return getTest(onwha);
                } else {
                    return (getLabel() == onwha);
                }
                return false;
            };
        }
    ]);