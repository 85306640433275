app
    .controller('AppCtrl', ['$scope', '$rootScope', '$uibModal', '$state', '$location', '$userModel', '$stateParams', '$window', '$timeout', '$q', 'ngProgressFactory', function ($scope, $rootScope, $uibModal, $state, $location, $userModel, $stateParams, $window, $timeout, $q, ngProgressFactory) {
        $rootScope.progressbar = ngProgressFactory.createInstance();
        $scope.login = function () {
            // var loginUrl = $state.current
            $state.go($rootScope.currentState + '.login');
        };
        this.openCareerPopup = function (item) {
            $uibModal.open({
                templateUrl: 'templates/careerPopup.html',
                controller: 'careerCtrl',
                resolve: {
                    param: function () {
                        return {'data': item}
                    }
                }
            });
        };
        $scope.search = function (item) {
            var query = '';
            if ($scope.locdtls) {
                query += 'latitude=' + $scope.locdtls.lat;
                query += '&longitude=' + $scope.locdtls.lng;
                if ($scope.locdtls.sublocality_level_1) {
                    query += '&location=' + $scope.locdtls.sublocality_level_1;
                } else if ($scope.locdtls.locality) {
                    query += '&location=' + $scope.locdtls.locality;
                }
                query += '&city=' + $scope.locdtls.locality;
            }
            if (item) {
                query += 'house_type=' + item;
            }
            // $location.path('/search?' + query);
            $window.location.href = '/search?' + query;
        };

        $scope.track = function () {
            if ($scope.track_id) {
                $window.location.href = '/rqrmnt/status?id=' + $scope.track_id;
            }
        }
        $scope.logout = function () {
            if ($userModel.isAuthenticated()) {
                $userModel.logout();
                $window.location.href = '/logout';
            }
        }
    }])
    .controller('authCtrl', ['$scope', '$rootScope', '$stateParams', '$state', '$timeout', '$userModel', '$sce', '$window', '$interval', 'GooglePlus', '$compile', function ($scope, $rootScope, $stateParams, $state, $timeout, $userModel, $sce, $window, $interval, GooglePlus, $compile) {
        if ($stateParams) {
            if ($stateParams.q === 'failed') {
                $scope.show = true;
                $scope.error = 'danger';
                $scope.message = $sce.trustAsHtml('Unauthorised Access! Verification code not found.');
            }
            if ($stateParams.q === 'success') {
                $scope.show = true;
                $scope.error = 'success';
                $scope.message = $sce.trustAsHtml('Your account has been activated successfully !');
            }
            if ($stateParams.q === 'successful') {
                $scope.show = true;
                $scope.error = 'success';
                $scope.message = $sce.trustAsHtml('Your password has been reset successfully!');
            }
        }
        angular.extend($scope, {
            cancle: function () {
                $scope.$dismiss();
            },
            signin: function () {
                $timeout(function () {
                    $state.go(pstate + '.login');
                });
                $state.go(pstate + '.login');
            },
            signup: function () {
                $timeout(function () {
                    $state.go(pstate + '.register');
                });
                $state.go(pstate + '.register');
            },
            rlink: function () {
                $timeout(function () {
                    $state.go(pstate + '.reset');
                });
                $state.go(pstate + '.reset');
            },
            login: function () {
                $scope.$broadcast('show-errors-check-validity');
                if ($scope.loginForm.$valid) {
                    $rootScope.progressBar(true);
                    var userData = {
                        email: $scope.user.email,
                        password: $scope.user.password
                    }
                    login(userData);
                }
            },
            register: function () {
                $scope.$broadcast('show-errors-check-validity');
                if ($scope.registerForm.$valid) {
                    $rootScope.progressBar(true);
                    var userData = {
                        name: $scope.user.name,
                        email: $scope.user.email,
                        username: $scope.user.contact,
                        password: $scope.user.password
                    }
                    register(userData);
                }
            },
            request: function () {
                $scope.$broadcast('show-errors-check-validity');
                if ($scope.requestForm.$valid) {
                    $rootScope.progressBar(true);
                    var userData = {
                        email: $scope.user.email,
                    }
                    rRequest(userData);
                }
            },
            reset: function () {
                $scope.$broadcast('show-errors-check-validity');
                if ($scope.resetForm.$valid) {
                    $rootScope.progressBar(true);
                    var userData = {
                        email: $scope.user.email,
                    }
                    rProcess($scope.user);
                }
            },

            resent: function () {
                if ($scope.resentMail) {
                    var userData = {
                        email: $scope.resentMail,
                    }
                    resentMail(userData);
                }
            },
            facebook: function () {
                fbLogin();
            },
            google: function () {
                gLogin();
            },
        });
        function login(data) {
            $userModel.login(data).then(function (result) {
                if (result.data.success) {
                    if ($userModel.isAuthenticated()) {
                        if ($state.current.name == 'checkout.auth') {
                            // $window.location.reload();
                            $state.reload();
                        } else {
                            $window.location.href = '/dashboard'
                        }
                        // var token = $userModel.getUserObject();
                        // $window.location.href = '/dashboard';
                    }
                    // ;
                } else {
                    $scope.show = true;
                    $scope.error = 'danger';
                    if (result.data.hasOwnProperty('activated') && !result.data.activated) {
                        $scope.message = result.data.message;
                        $scope.resentMail = $scope.user.email;
                        var html = $compile('<br><span><a href="javascript:void(0);" data-ng-model="resentMail" ng-click="resent()" remove-me>Resend Mail</a></span>')($scope);
                        html.insertAfter(document.getElementById('message'));
                    } else {
                        $scope.message = $sce.trustAsHtml(result.data.message);
                    }
                }
                $rootScope.progressBar(false);
            }).catch(function (data) {
                $rootScope.progressBar(false);
            });
        };


        function register(data) {
            $userModel.register(data).then(function (result) {
                if (result.data.success) {
                    $scope.show = true;
                    $scope.message = $sce.trustAsHtml(result.data.message);
                } else {
                    $scope.show = true;
                    $scope.message = $sce.trustAsHtml(result.data.message);
                }
                $rootScope.progressBar(false);
                // $scope.reset();
            }).catch(function (data) {
                $rootScope.progressBar(false);
            });
        }

        function rRequest(data) {
            $userModel.resetRequest(data).then(function (result) {
                if (result.data.success) {
                    $scope.error = 'success';
                    $scope.rf = true;
                } else {
                    $scope.error = 'danger';
                    $scope.rf = false;
                }
                $scope.show = true;
                $scope.message = $sce.trustAsHtml(result.data.message);
                $scope.resetForm.$setPristine();
                $scope.resetForm.$setUntouched();
                $scope.$broadcast('how-errors-reset');
                $rootScope.progressBar(false);
                // $scope.reset();
            }).catch(function (data) {
                $rootScope.progressBar(false);
            });
        };
        function rProcess(data) {
            $userModel.resetProcess(data).then(function (result) {
                if (result.data.success) {
                    // $scope.show = true;
                    // $scope.error = 'success';
                    // $scope.message = $sce.trustAsHtml(result.data.message);
                    $timeout(function () {
                        $state.go(pstate + '.login', {q: 'successful'});
                    });
                    $state.go(pstate + '.login', {q: 'successful'});
                } else {
                    $scope.show = true;
                    $scope.error = 'danger';
                    $scope.message = $sce.trustAsHtml(result.data.message);
                    $scope.$broadcast('how-errors-reset');
                }
                $rootScope.progressBar(false);
                // $scope.reset();
            }).catch(function (error) {
                $rootScope.progressBar(false);
                throw error;
            });
        };
        function resentMail(data) {
            $userModel.resentMail(data).then(function (result) {
                if (result.data.success) {
                    $scope.show = true;
                    $scope.error = 'success';
                    $scope.message = $sce.trustAsHtml(result.data.message);
                } else {
                    $scope.show = true;
                    $scope.error = 'danger';
                    $scope.message = $sce.trustAsHtml(result.data.message);
                }
            });
        }

        function fbLogin() {
            FB.login(function (response) {
                if (response.status == "connected" && response.authResponse) {
                    FB.api('/me', {fields: 'name,email'}, function (response) {
                        var accessToken = FB.getAuthResponse();
                        var socialData = angular.extend(response, accessToken, {provider: 'facebook'});
                        $userModel.socialLogin(socialData).then(function (result) {
                            if (result.data.success) {
                                if ($userModel.isAuthenticated()) {
                                    // var token = $userModel.getUserObject();
                                    $window.location.href = '/dashboard';
                                }
                            } else {
                                $scope.show = true;
                                $scope.message = $sce.trustAsHtml(result.data.message);
                            }
                        })
                    });
                } else {
                    console.log('user canceled');
                }
            }, {scope: 'email,user_likes'});
        };

        function gLogin() {
            GooglePlus.login().then(function (authResult) {
                if (authResult) {
                    var access_token = authResult.access_token;
                    GooglePlus.getUser().then(function (user) {
                        var socialData = angular.extend(user, {accessToken: access_token, provider: 'google'});
                        $userModel.socialLogin(socialData).then(function (result) {
                            if (result.data.success) {
                                if ($userModel.isAuthenticated()) {
                                    // var token = $userModel.getUserObject();
                                    $window.location.href = '/dashboard';
                                }
                            } else {
                                $scope.show = true;
                                $scope.message = $sce.trustAsHtml(result.data.message);
                            }
                        })
                    });
                } else {
                    console.log('user canceled');
                }

            }, function (err) {
                console.log(err);
            });
        }


    }])
    .controller('contactController', ['$scope', '$userModel', function ($scope, $userModel) {
        angular.extend($scope, {
            contactUs: function () {
                $scope.$broadcast('show-errors-check-validity');
                if ($scope.contactForm.$valid) {
                    var value = 'lastname=' + $scope.contact.name + '&firstname=' + $scope.contact.name;
                    value += '&email=' + $scope.contact.email + '&mobile=' + $scope.contact.contact;
                    value += '&description=' + $scope.contact.message + '&leadsource="Contact Us"';
                    value += '&name="Contact Us"&publicid=1184ac71984941bce1e5a045df9a72fc';
                    $userModel.crmPost(value).then(function (result) {
                        if (result.data.success === true && result.data.result === 'ok') {
                            $scope.show = true;
                        }
                    });
                }
                $scope.reset = function () {
                    $scope.$broadcast('show-errors-reset');
                    $scope.contactForm = {contactName: '', contactEmail: '', contactNo: '', message: ''};
                }
            }
        });
    }])

    .controller('callBackCtrl', ['$scope', '$userModel', '$sce', '$uibModal', function ($scope, $userModel, $sce, $uibModal) {
        angular.extend($scope, {
            openCallback: function () {
                $uibModal.open({
                    templateUrl: 'templates/callBackPopup.html',
                    controller: 'callBackCtrl',
                    windowClass: 'call_back'
                });
            },
            callBack: function () {
                $scope.$broadcast('show-errors-check-validity');
                if ($scope.callbackForm.$valid) {
                    var value = 'lastname=' + $scope.callback.name + '&firstname=' + $scope.callback.name;
                    value += '&email=' + $scope.callback.email + '&mobile=' + $scope.callback.contact;
                    value += '&leadsource="CallBack"&name="CallBack"';
                    value += '&publicid=f9af0948e472ed09aabcf64feca65a80';
                    $userModel.crmPost(value).then(function (result) {
                        if (result.data.success === true && result.data.result === 'ok') {
                            $scope.show = true;
                        }
                    });
                }
            },
            cancle: function () {
                $scope.$dismiss();
            }
        });
    }])

    .controller('careerCtrl', ['$scope', '$rootScope', '$interval', '$uibModalInstance', '$userModel', '$filter', 'param', function ($scope, $rootScope, $interval, $uibModalInstance, $userModel, $filter, param) {
        angular.extend($scope, {
            head: param.data,
            close: function () {
                $uibModalInstance.dismiss('cancel');
            },
            career: {
                gender: 'male',
            },
            postCareer: function () {
                $scope.$broadcast('show-errors-check-validity');
                if ($scope.careerForm.$valid) {
                    $rootScope.progressBar(true);
                    var value = 'lastname=' + $scope.career.name + '&firstname=' + $scope.career.name;
                    value += '&email=' + $scope.career.email + '&mobile=' + $scope.career.contact + '&label:Category=' + $scope.head;
                    value += '&label:Address=' + $scope.career.address + '&label:Gender=' + $scope.career.gender;
                    value += '&designation=' + $scope.career.profession + '&label:Experience=' + $scope.career.exp + '&description=' + $scope.career.about + '&label:Age=' + $scope.career.age;
                    value += '&leadsource="Career"&name="Career"';
                    value += '&publicid=af9158ad7648e182c018c5117a799ea3';
                    $userModel.crmPost(value).then(function (result) {
                        if (result.data.success === true && result.data.result === 'ok') {
                            $scope.show = true;
                            $rootScope.progressBar(false);
                        }
                    });

                }
            }

        })
    }])

    .controller('searchCtrl', ['$scope', '$userModel', '$window', function ($scope, $userModel, $window) {
        angular.extend($scope, {
            // citiesList: ddlCity(),
            bhks: ddlCategory('rooms'),
            options: {
                country: 'IN',
                types: 'geocode',
            },
            // selectedCity: 'Noida',
            // selectCity: function (item) {
            //     $scope.selectedCity = item
            // },
            selectBHK: function (item) {
                $scope.selectedBHK = item
            },
            search: function (param) {
                search(param);
            },
        });
        function ddlCategory(type) {
            $userModel.categories(type).then(function (result) {
                if (result.data.success) {
                    $scope.bhks = result.data.category;
                }
            });
        }

        function ddlCity() {
            $userModel.cities().then(function (result) {
                if (result.data.success) {
                    $scope.cities = result.data.city;
                }
            });
        }

        function search(param) {
            var query = '';
            // if ($scope.searchForm.$valid) {
            if ($scope.locdtls) {
                query += 'latitude=' + $scope.locdtls.lat;
                query += '&longitude=' + $scope.locdtls.lng;
                if ($scope.locdtls.sublocality_level_1) {
                    query += '&location=' + $scope.locdtls.sublocality_level_1;
                } else if ($scope.locdtls.locality) {
                    query += '&location=' + $scope.locdtls.locality;
                }
                query += '&city=' + $scope.locdtls.locality;
            }
            if ($scope.selectedBHK) {
                query += '&room=' + $scope.selectedBHK.text;
            }
            if (param) {
                query += '&house_type=' + param;
            }
            $window.location.href = '/search?' + query;
        }
    }])

    .controller('ticketCtrl', ['$scope', '$stateParams', '$rootScope', '$filter', 'resolveData', '$cookies', function ($e, $sp, $rscope, $filter, rowdata, $cookies) {
        $cookies.remove('_ord');
        if (!rowdata.data.success) {
            $rscope.error_message = rowdata.data.message;
            $s.go('checkout.failed');
        }
        var item = rowdata.data.ticket;
        angular.extend($e, {
            tittle: item.furnish + ', ' + item.accomodation + ', ' + item.category,
            gender: item.gender,
            pname: item.pname,
            ploc: item.hno ? item.hno + ', ' + item.address : item.address,
            booking_date: item.book_date,
            movein: item.move_in,
            amnt: parseInt(item.paid_amnt),
            bk_id: item.booking_id,
            bk_type: item.book_type,
            ref_no: item.reference_no,
            name: item.name,
            email: item.email,
            contact: item.contact,
            rm_no: item.rm_no,
            bed_no: item.bed_no,
            rm_floor: item.floor,
            trans_id: item.trans_id,
            rm_amen: item.room_amenities,
            services: item.services,
        });
    }])

    .controller('referenceCtrl', ['$scope', '$window', function ($e, $w) {
        $e.search = function () {
            $e.$broadcast('show-errors-check-validity');
            if ($e.searchForm.$valid) {
                $w.location.href = '/details/' + $e.reference_no;
            }
        }
    }])
    .controller('neighbourhoodCtrl', ['$scope', '$rootScope', '$state', '$userModel', '$timeout', '$window', '$filter', '$q', function ($scope, $rootScope, $state, $userModel, $timeout, $window, $filter, $q) {
        var searchLocation = '';
        angular.extend($scope, {
            simulateQuery: false,
            isDisabled: false,
            states: [],
            querySearch: querySearch,
            selectedItemChange: selectedItemChange,
            // searchTextChange: searchTextChange,
            newState: newState,
            search: function () {
                if (searchLocation && searchLocation.hasOwnProperty('value')) {
                    var loc = searchLocation.hasOwnProperty('location') ? searchLocation.location.toLowerCase() : '';
                    var city = searchLocation.hasOwnProperty('city') ? searchLocation.city.toLowerCase() : '';
                    var url = loc + ' ' + city;
                    url = $filter('slugify')(url);
                    $window.location.href = 'location/' + searchLocation.value + '/' + url;
                }
            }
        })


        // list of `state` value/display objects


        function newState(state) {
            alert("Sorry! You'll need to create a Constitution for " + state + " first!");
        }

        // ******************************
        // Internal methods
        // ******************************

        /**
         * Search for states... use $timeout to simulate
         * remote dataservice call.
         */
        function querySearch(query) {
            return loadAll(query).then(function (value) {
                var results = query ? value.filter(createFilterFor(query)) : value;
                return results;
            });
        }

        // function searchTextChange(text) {
        //     // console.log('Text changed to ' + text);
        // }

        function selectedItemChange(item) {
            searchLocation = item;
        }

        /**
         * Build `states` list of key/value pairs
         */
        function loadAll(query) {
            var data = {
                location: query ? JSON.stringify(query.split(/[ ,]+/)) : query,
                city: 3,
            }
            return $userModel.autoLocation(data).then(function (result) {
                if (result.data.success) {
                    var loadItems = [];
                    loadItems = result.data.location.map(function (obj) {
                        return {
                            value: obj.id,
                            display: obj.location_name + ' ' + obj.city,
                            location: obj.location_name,
                            city: obj.city
                        };
                    });
                    return $scope.states = loadItems;
                }
            })
        }
        
        /**
         * Create filter function for a query string
         */
        function createFilterFor(query) {
            var item = [];
            if (query) {
                item = query.split(/[ ,]+/);
            }
            // console.log(item.length);
            var lowercaseQuery = angular.lowercase(query);
            return function filterFn(state) {
                var result = false;
                for (var i = 0; i <= item.length; i++) {
                    if (state.location.toLowerCase().indexOf(angular.lowercase(item[i])) > -1) {
                        result = true;
                    }
                    if (state.city.toLowerCase().indexOf(angular.lowercase(item[i])) > -1) {
                        result = true;
                    }
                }
                return result;
            };

        }
    }])
